var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('div',{staticClass:"conOfHeader pl-5"},[_c('div',{staticClass:"headerOfPage"},[_vm._v("All Job Orders")])])])],1),_c('div',{staticClass:"d-flex mb-5 justify-space-between"},[_c('form',{staticClass:"conFormSubmit conSearchForm mt-5"},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"6","cols":"12"}},[_c('label',{staticClass:"eachLabel"},[_vm._v("Status")]),_c('v-select',{attrs:{"outlined":"","items":_vm.statusList,"item-text":"name","item-value":"id","placeholder":"Status","hide-details":"true","menu-props":{ bottom: true, offsetY: true },"attach":"","append-icon":"mdi-chevron-down"},model:{value:(_vm.search.status),callback:function ($$v) {_vm.$set(_vm.search, "status", $$v)},expression:"search.status"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"md":"6","sm":"6","cols":"12"}},[_c('v-col',{staticClass:"confBtnSec d-flex",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"srearchBnt btnAddPrimaryOutline mr-4",attrs:{"disabled":_vm.isLoadingSearch,"loading":_vm.isLoadingSearch},on:{"click":_vm.onSearch}},[_vm._v(" SEARCH ")]),_c('v-btn',{staticClass:"clearBtn btnCancelOutline mr-4",on:{"click":_vm.onClear}},[_vm._v(" CLEAR ")]),(
                (_vm.user.parent_id == 4 && _vm.user.children_id == null) ||
                _vm.user.parent_id == 10
              )?_c('v-btn',{staticClass:"btnReminderOutline mr-4",attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":function($event){return _vm.sendReminder()}}},[_c('v-icon',[_vm._v("mdi-bell-outline")]),_vm._v("CEO ")],1):_vm._e(),(
                (_vm.user.parent_id == 4 && _vm.user.children_id == null) ||
                _vm.user.parent_id == 10
              )?_c('v-btn',{staticClass:"btnReminderOutline mr-4",attrs:{"disabled":_vm.isLoadingSales,"loading":_vm.isLoadingSales},on:{"click":function($event){return _vm.sendSalesReminder()}}},[_c('v-icon',[_vm._v("mdi-bell-outline")]),_vm._v("Sales ")],1):_vm._e()],1),_c('v-col',{attrs:{"md":"12"}})],1)],1)],1),_c('form',{staticClass:"conFormSubmit conSearchForm mt-5",on:{"submit":function($event){$event.preventDefault();return _vm.onSearchTxt()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"eachLabel"},[_vm._v("Search")]),_c('v-text-field',{staticClass:"form-control",attrs:{"outlined":"","type":"text","id":"inputSearch","placeholder":"Search","aria-label":"Search","aria-describedby":"search-addon","append-icon":"mdi-magnify"},model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}})],1)],1)],1)]),(_vm.listData && _vm.listData.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('section',{staticClass:"conOfProjects"},[_c('div',{staticClass:"conOfTable"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listData,"items-per-page":7,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                  name: 'jobOrdersProcess',
                  params: { id: item.id },
                }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":true,"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.jobOrderPreview(item)}}},[_c('v-list-item-title',{staticClass:"statusTxt"},[_vm._v(" Preview ")])],1)],1)],1)]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))])]}}],null,true)})],1)]),(_vm.pagination.total > _vm.pagination.per_page)?_c('div',{staticClass:"conOfPagination pr-5 pt-5"},[_c('v-pagination',{attrs:{"total-visible":7,"length":_vm.pagination.last_page},on:{"input":function($event){return _vm.goToPage(_vm.pagination.current_page)}},model:{value:(_vm.pagination.current_page),callback:function ($$v) {_vm.$set(_vm.pagination, "current_page", $$v)},expression:"pagination.current_page"}})],1):_vm._e()])],1):(_vm.isLoading)?_c('div',{staticClass:"loaderContainer"},[_c('v-progress-circular',{attrs:{"size":120,"width":4,"indeterminate":"","color":"primary"}})],1):_c('v-row',[_c('v-col',{staticClass:"mt-7",attrs:{"cols":"12"}},[_c('EmptyState')],1)],1),_c('v-snackbar',{attrs:{"color":"success","shaped":"","absolute":"","top":"","right":"","timeout":_vm.timeout},model:{value:(_vm.successSnackbar),callback:function ($$v) {_vm.successSnackbar=$$v},expression:"successSnackbar"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]),_c('v-snackbar',{attrs:{"color":"red","shaped":"","top":"","right":"","timeout":_vm.timeout},model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }